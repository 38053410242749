import React from 'react';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import '../../css/Footer.css';

export default class SocialMedia extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            msg: props.msg
        }

        this.handleClickF = this.handleClickF.bind(this);
        this.handleClickI = this.handleClickI.bind(this);
        this.handleClickL = this.handleClickL.bind(this);
        this.handleClickY = this.handleClickY.bind(this);
    }

    //handler
    handleClickF(event) {
        window.open('https://www.facebook.com/grupou2/', "_blank");
    };

    handleClickI(event) {
        window.open('https://www.instagram.com/grupou2/', "_blank");
    };

    handleClickL(event) {
        window.open('https://www.linkedin.com/company/grupou2/', "_blank");
    };

    handleClickY(event) {
        window.open('https://www.youtube.com/channel/UCZ1MVfvqsXCkIVkp4jtFBsg/', "_blank");
    };

    render() {
        const { msg } = this.state;
        return (
            <div className="Footer-div">
                <div>
                    <Typography className="Footer-font2" variant="h6">
                        <b>{msg}</b>
                    </Typography>
                    <div className="Footer-icons-text">
                        <div className="Footer-social-media">
                            <IconButton
                                id="facebook"
                                onClick={(e) => this.handleClickF(e)}
                            >
                                <FacebookIcon id="facebook" fontSize="large" />
                            </IconButton>
                            <IconButton
                                id="instagram"
                                onClick={(e) => this.handleClickI(e)}
                            >
                                <InstagramIcon id="instagram" fontSize="large" />
                            </IconButton>
                            <IconButton
                                id="linkedin"
                                onClick={(e) => this.handleClickL(e)}
                            >
                                <LinkedInIcon id="linkedin" fontSize="large" />
                            </IconButton>
                            <IconButton
                                id="youtube"
                                onClick={(e) => this.handleClickY(e)}
                            >
                                <YouTubeIcon id="youtube" fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
