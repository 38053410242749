import React from 'react';
import Typography from '@material-ui/core/Typography';
import '../../css/Us.css';

export default class Highlights extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className="Us">
                <div className="Us-title">
                    <img src='https://imagesu2.blob.core.windows.net/img/b2.jpg'></img>
                </div>
            </div>
        );
    }
}
