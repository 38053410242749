import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './css/index.css';
import App from './App';
import Header from "./js/components/Header";
import Footer from "./js/components/Footer";
import Us from "./js/components/Us";
import Highlights from "./js/components/Highlights";
import Responsability from "./js/components/Responsability";
import Location from "./js/components/Location";
import Contact from "./js/components/Contact";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/us" element={<Us />} />
      {/* <Route path="/highlights" element={<Highlights />} />
      <Route path="/responsability" element={<Responsability />} /> */}
      {/* <Route path="/location" element={<Location />} /> */}
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
