import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SocialMedia from './SocialMedia';
import '../../css/InfoGrid.css';

export default class InfoGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    return (
      <div className="InfoGrid">
        <div>
          <img className='InfoGrid-img' src='https://imagesu2.blob.core.windows.net/img/u2_room.jpg'></img>
        </div>
        <div className='InfoGrid-info'>
          <Typography variant='h3'>
            <b><span className='we'>SOMOS O </span><span className='we2'>GRUPO U2</span></b>
          </Typography>
          <Typography paragraph variant='h6'>
            <br />A conexão que gera resultados
          </Typography>
          <Typography paragraph variant='subtitle2'>
            Uma empresa que acompanha o progresso do agronegócio em diversas regiões do país, desenvolvendo negócios que vão desde o plantio, beneficiamento, armazenagem e comercialização de grãos, até a distribuição insumos pecuários, fábricas de rações e oportunidade de bons negócios.
          </Typography>
          <div className='InfoGrid-div-button'>
            <Button href='/us' className='InfoGrid-button' variant="contained">Conheça mais</Button>
          </div>
          <div className='InfoGrid-social'>
            <SocialMedia msg="Conheça nossas redes sociais" />
          </div>
        </div>
      </div>
    );
  }
}
