import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import '../../css/Us.css';
import '../../css/Contact.css';


const styles = {
    root: {
        background: "black"
    },
    input: {
        color: "#2EFF22"
    },
    textField: {
        color: "grey",
        '&$focused $notchedOutline': {
            borderColor: '#4A90E2',
            borderWidth: 1,
        },
    }
};

const SERVICE_ID = "service_qdrfst8";
const TEMPLATE_ID = "template_0r6nxal";
const USER_ID = "-o5B7FPXDuuF_97y1";

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            telefone: null,
            endereco: '',
            bairro: '',
            cidade: '',
            mensagem: '',
            estado: null,
            assunto: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeState(event) {
        this.setState({ estado: event.target.value });
    }

    handleChange(event) {
        switch (event.target.id) {
            case 'name': {
                this.setState({ name: event.target.value });
                break;
            }
            case 'email': {
                this.setState({ email: event.target.value });
                break;
            }
            case 'telefone': {
                this.setState({ telefone: event.target.value });
                break;
            }
            case 'endereco': {
                this.setState({ endereco: event.target.value });
                break;
            }
            case 'bairro': {
                this.setState({ bairro: event.target.value });
                break;
            }
            case 'cidade': {
                this.setState({ cidade: event.target.value });
                break;
            }
            case 'mensagem': {
                this.setState({ mensagem: event.target.value });
                break;
            }
            default: {
                this.setState({ assunto: event.target.value });
                break;
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        emailjs.send(SERVICE_ID, TEMPLATE_ID, this.state, USER_ID)
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Mensagem enviada com sucesso!'
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao enviar a mensagem, tente novamente.',
                    text: error.text,
                })
            });
        event.target.reset();
        this.setState({ estado: null, assunto: null });
    }

    render() {
        const { classes } = this.props;
        const { assunto, estado } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <FormControl fullWidth>
                    <TextField
                        required
                        id="assunto"
                        select
                        label="Assunto"
                        value={assunto}
                        variant="outlined"
                        size="small"
                        onChange={this.handleChange}
                    >
                        <MenuItem value={"Orçamentos"}>Orçamentos</MenuItem>
                        <MenuItem value={"Sugestões"}>Sugestões</MenuItem>
                        <MenuItem value={"Críticas"}>Críticas</MenuItem>
                    </TextField>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField required id="name" label="Nome ou Razão Social" variant="outlined" onChange={this.handleChange} size="small" />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField required id="email" email label="E-mail" variant="outlined" onChange={this.handleChange} size="small" />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField required id="telefone" label="Telefone" variant="outlined" onChange={this.handleChange} size="small" />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField id="endereco" label="Endereço" variant="outlined" onChange={this.handleChange} size="small" />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField id="bairro" label="Bairro" variant="outlined" onChange={this.handleChange} size="small" />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <div className='Form-address'>
                        <TextField style={{ marginRight: "1rem" }} id="cidade" label="Cidade" variant="outlined" onChange={this.handleChange} size="small" />
                        <TextField
                            id="estado"
                            select
                            label="Estado"
                            value={estado}
                            variant="outlined"
                            size="small"
                            onChange={this.handleChangeState}
                        >
                            <MenuItem value={"Acre-AC"}>Acre</MenuItem>
                            <MenuItem value={"Alagoas-AL"}>Alagoas</MenuItem>
                            <MenuItem value={"Amapá-AP"}>Amapá</MenuItem>
                            <MenuItem value={"Amazonas-AM"}>Amazonas</MenuItem>
                            <MenuItem value={"Bahia-BA"}>Bahia</MenuItem>
                            <MenuItem value={"Ceará-CE"}>Ceará</MenuItem>
                            <MenuItem value={"DistritoFederal-DF"}>Distrito Federal</MenuItem>
                            <MenuItem value={"EspíritoSanto-ES"}>Espírito Santo</MenuItem>
                            <MenuItem value={"Goiás-GO"}>Goiás</MenuItem>
                            <MenuItem value={"Maranhão-MA"}>Maranhão</MenuItem>
                            <MenuItem value={"MatoGrosso-MT"}>Mato Grosso</MenuItem>
                            <MenuItem value={"MatoGrossodoSul-MS"}>Mato Grosso do Sul</MenuItem>
                            <MenuItem value={"MinasGerais-MG"}>Minas Gerais</MenuItem>
                            <MenuItem value={"Pará-PA"}>Pará</MenuItem>
                            <MenuItem value={"Paraíba-PB"}>Paraíba</MenuItem>
                            <MenuItem value={"Paraná-PR"}>Paraná</MenuItem>
                            <MenuItem value={"Pernambuco-PE"}>Pernambuco</MenuItem>
                            <MenuItem value={"Piauí-PI"}>Piauí</MenuItem>
                            <MenuItem value={"RiodeJaneiro-RJ"}>Rio de Janeiro</MenuItem>
                            <MenuItem value={"RioGrandedoNorte-RN"}>Rio Grande do Norte</MenuItem>
                            <MenuItem value={"RioGrandedoSul-RS"}>Rio Grande do Sul</MenuItem>
                            <MenuItem value={"Rondônia-RO"}>Rondônia</MenuItem>
                            <MenuItem value={"Roraima-RR"}>Roraima</MenuItem>
                            <MenuItem value={"SantaCatarina-SC"}>Santa Catarina</MenuItem>
                            <MenuItem value={"SãoPaulo-SP"}>São Paulo</MenuItem>
                            <MenuItem value={"Sergipe-SE"}>Sergipe</MenuItem>
                            <MenuItem value={"Tocantins-TO"}>Tocantins</MenuItem>
                        </TextField>
                    </div>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                    <TextField required multiline id="mensagem" label="Mensagem" variant="outlined" onChange={this.handleChange} minRows="8" />
                </FormControl>
                <div style={{ marginTop: "1rem", position: "relative" }}>
                    <Button style={{
                        position: 'absolute',
                        right: 0
                    }} type="submit" value="Submit" variant="contained">Enviar</Button>
                </div>
            </form>
        );
    }
}

export default withStyles(styles)(ContactForm);