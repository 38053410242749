import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from 'react-responsive'
import MediaCard from './MediaCard';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 850 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 851 })
    return isNotMobile ? children : null
}

export default function GridCompanies(props) {
    const { children, display, ...other } = props;


    return (
        <Box sx={{ width: '100%' }}>
            <Default>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2BANK.jpg" title="U2BANK" message="O banco digital que nasceu pra te ajudar a fazer acontecer
O u2BANK é uma plataforma digital que simplifica a vida das pessoas, impulsionando as a realizar seus sonhos.-
Além de conta corrente e cartão de débito e crédito, o U2BANK oferece diversas opções de investimentos, empréstimos, seguros e muito mais.-
Por ter uma atuação totalmente focada nas pessoas, o U2BANK visa crescer de forma sustentável, garantindo a qualidade dos serviços e a melhor experiência aos clientes.
"/>
                    </Grid>
                    <Grid item xs={4}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/INVAGRO.jpg" title="INVAGRO" message="Somos a renda fixa mais rentável do mercado e mais rentável que a maioria das empresas de renda variável. -Com segurança contratual, através do fundo garantidor, auxílio jurídico e contábil para a sua renda gerada pela nossa empresa e flexibilidade para escolher o melhor plano de resgates para o seu dia a dia." />
                    </Grid>
                    <Grid item xs={4}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2LOG.jpg" title="U2LOG" message="Com foco nas operações de compra e venda de trigo, milho e soja, a U2LOG é o elo fundamental que une os principais atores deste gigantesco mercado que é o agronegócio brasileiro.-

A U2LOG também atua nas negociações de outros cereais e insumos do agronegócio.-

Vendedores e compradores de cereais podem contar com o profissionalismo, conhecimento e experiência que a U2LOG traz no seu quadro societário e na sua estrutura.
" />
                    </Grid>
                    <div className='GridCompanies'>
                        <Grid item xs={4}>
                            <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/DAILY_PETFOOD.jpg" title="DAILY PETFOOD" message="A Dailly Pet Food produz alimentos para cães e gatos com alto padrão de qualidade que envolve tecnologia de ponta, elevado grau de automação, colaboradores capacitados e fornecedores qualificados.-

Mais do que nutrir e alimentar, a Daily oferece excelência e comprometimento com a perfeita alimentação do seu animal de estimação, utilizando sempre o que há de mais atual na produção de alimentos para cães e gatos.
" />
                        </Grid>
                        <Grid item xs={4}>
                            <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2_NUTRIcaO_ANIMAL.jpg" title="U2 NUTRIÇÃO ANIMAL" message="A Animal oferece muito mais do que alimentos, rações e suplementos. Oferece produtos completos, funcionais, nutritivos e tecnológicos. - Por trás de nossos produtos, há muita tecnologia e estudo, que ajudam a transformar matérias primas em alimentos completos, proporcionando o desempenho desejado para cada animal" />
                        </Grid>
                    </div>
                </Grid>
            </Default>
            <Mobile>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2BANK.jpg" title="U2BANK" message="O banco digital que nasceu pra te ajudar a fazer acontecer
O u2BANK é uma plataforma digital que simplifica a vida das pessoas, impulsionando as a realizar seus sonhos.-
Além de conta corrente e cartão de débito e crédito, o U2BANK oferece diversas opções de investimentos, empréstimos, seguros e muito mais.-
Por ter uma atuação totalmente focada nas pessoas, o U2BANK visa crescer de forma sustentável, garantindo a qualidade dos serviços e a melhor experiência aos clientes.
"/>
                    </Grid>
                    <Grid item xs={12}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/INVAGRO.jpg" title="INVAGRO" message="Somos a renda fixa mais rentável do mercado e mais rentável que a maioria das empresas de renda variável. -Com segurança contratual, através do fundo garantidor, auxílio jurídico e contábil para a sua renda gerada pela nossa empresa e flexibilidade para escolher o melhor plano de resgates para o seu dia a dia." />
                    </Grid>
                    <Grid item xs={12}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2LOG.jpg" title="U2LOG" message="Com foco nas operações de compra e venda de trigo, milho e soja, a U2LOG é o elo fundamental que une os principais atores deste gigantesco mercado que é o agronegócio brasileiro.-

A U2LOG também atua nas negociações de outros cereais e insumos do agronegócio.-

Vendedores e compradores de cereais podem contar com o profissionalismo, conhecimento e experiência que a U2LOG traz no seu quadro societário e na sua estrutura.
" />
                    </Grid>
                    <Grid item xs={12}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/U2_NUTRIcaO_ANIMAL.jpg" title="U2 NUTRIÇÃO ANIMAL" message="A Animal oferece muito mais do que alimentos, rações e suplementos. Oferece produtos completos, funcionais, nutritivos e tecnológicos. - Por trás de nossos produtos, há muita tecnologia e estudo, que ajudam a transformar matérias primas em alimentos completos, proporcionando o desempenho desejado para cada animal" />
                    </Grid>
                    <Grid item xs={12}>
                        <MediaCard display={display} src="https://imagesu2.blob.core.windows.net/img/DAILY_PETFOOD.jpg" title="DAILY PETFOOD" message="A Dailly Pet Food produz alimentos para cães e gatos com alto padrão de qualidade que envolve tecnologia de ponta, elevado grau de automação, colaboradores capacitados e fornecedores qualificados.-

Mais do que nutrir e alimentar, a Daily oferece excelência e comprometimento com a perfeita alimentação do seu animal de estimação, utilizando sempre o que há de mais atual na produção de alimentos para cães e gatos.
" />
                    </Grid>
                </Grid>
            </Mobile>
        </Box>
    );
}