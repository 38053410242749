import React from 'react';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SocialMedia from './SocialMedia';
import '../../css/Footer.css';

export default class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.handleClick = this.handleClick.bind(this);
    }

    //handler
    handleClick(event) {
        console.log(event.target.id);
    };

    render() {
        return (
            <div className="Footer">
                <div className="Footer-content">
                    {/* <div className="Footer-div">
                        <div>
                            <Typography className="Footer-font" variant="h6">
                                <b>Localização</b>
                            </Typography>
                            <div className="Footer-icons-text">
                                <LocationOnIcon />
                                <Typography variant="body2">
                                    Av. Lorem ipsum, 0000 - Magna Aliqua - Iapu/MG
                                </Typography>
                            </div>
                        </div>
                    </div> */}
                    <div className="Footer-div">
                        <div>
                            <Typography className="Footer-font" variant="h6">
                                <b>Fale com nosso SAC</b>
                            </Typography>
                            <div className="Footer-icons-text">
                                <EmailIcon />
                                <Typography variant="body2">
                                    contato@gpuniao.com.br
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="Footer-div">
                        <div>
                            <Typography className="Footer-font" variant="h6">
                                <b>Atendimento</b>
                            </Typography>
                            <div className="Footer-icons-text">
                                <PhoneIcon />
                                <Typography variant="body2">
                                    0800 355 5555
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <SocialMedia msg="Siga-nos nas redes"/>
                </div>
            </div>
        );
    }
}
