import React from 'react';
import Typography from '@material-ui/core/Typography';
import '../../css/Us.css';

export default class Mission extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className='Us-message'>
                <Typography variant='h6' paragraph>
                    MISSÃO
                </Typography>
                <Typography variant='subtitle2' paragraph>
                    Fornecer e distribuir produtos e serviços de qualidade, com pontualidade, buscando inovação, gerando crescimento profissional com qualidade e eficácia.
                </Typography>
                <Typography variant='h6' paragraph>
                    VISÃO
                </Typography>
                <Typography variant='subtitle2' paragraph>
                    Ser uma empresa sólida, sustentável e referência na produção, distribuição e comercialização de produtos para Nutrição Animal no Estado de Minas Gerais.
                </Typography>
                <Typography variant='h6' paragraph>
                    VALORES
                </Typography>
                <ul>
                    <li>
                        <Typography variant='subtitle2'>
                            União
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Família
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Responsabilidade
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Disciplina
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Inovação
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Lealdade
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2'>
                            Qualidade
                        </Typography>
                    </li>
                </ul>
            </div>
        );
    }
}
