import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { useMediaQuery } from 'react-responsive'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../css/Header.css';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    borderRight: `1px solid #dcdcdc`,
    color: 'gray',
  },
  toolbarLink2: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexShrink: 0,
    color: 'gray',
  },
  paper: {
    marginRight: theme.spacing(10),
  },
}));

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 941 })
  return isMobile ? children : null
}

const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 942 })
  return isNotMobile ? children : null
}

export default function Header(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { title } = props;

  const sections = [
    { title: 'Home', url: '/' },
    { title: 'Quem Somos', url: '/us' },
    // { title: 'Destaques U2 Grupo', url: '/highlights' },
    // { title: 'Responsabilidade Social', url: '/responsability' },
    // { title: 'Localização', url: '/location' },
    { title: 'Contato', url: '/contact' },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="Header-fixed">
        <Default>
          <div className="Header-grid">
            <div>
              <img src="https://imagesu2.blob.core.windows.net/img/logo_u2.png" className="App-logo" alt="logo" />
            </div>
            <div></div>
            <div className="Header-grid-menu">
              <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                {sections.map((section, index) => (
                  <Link
                    color="inherit"
                    noWrap
                    key={section.title}
                    variant="body2"
                    href={section.url}
                    className={index + 1 == sections.length ? classes.toolbarLink2 : classes.toolbarLink}
                  >
                    {section.title}
                  </Link>
                ))}
              </Toolbar>
            </div>
          </div>
        </Default>
        <Mobile>
          <div className="Header-grid">
            <Toolbar>
              <img src="https://imagesu2.blob.core.windows.net/img/logo_u2.png" className="App-logo" alt="logo" />
            </Toolbar>
            <div></div>
            <div className="Header-menu-button">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <MenuIcon />
              </IconButton>
              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper className="Header-menu-list">
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          {sections.map((section) => (
                            <MenuItem>
                              <Link
                                color="inherit"
                                noWrap
                                key={section.title}
                                variant="body2"
                                href={section.url}
                                className={classes.toolbarLink2}
                              >
                                {section.title}
                              </Link>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Mobile>
      </div>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};