import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive';
import '../../css/VideoHome.css';

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 850 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 851 })
    return isNotMobile ? children : null
}

export default class VideoHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className="VideoHome">
                <div className='VideoHome-content'>
                    <Default>
                        <Typography variant='h3'>
                            <b><span className='we'>A nossa Cultura está no valor que geramos aos </span><span className='we2'>nossos clientes</span></b>
                        </Typography>
                    </Default>
                    <Mobile>
                        <Typography variant='h4'>
                            <b><span className='we'>A nossa Cultura está no valor que geramos aos </span><span className='we2'>nossos clientes</span></b>
                        </Typography>
                    </Mobile>
                    <br />
                    <br />
                    <div className='VideoHome-content-bottom'>
                        <Typography paragraph variant='h6' color='text.secondary'><b>União, não é apenas o que se transmite, mas também o que se acredita!</b></Typography>
                        <Button style={{ maxWidth: '10rem' }} href='/contact' className='InfoGrid-button' variant="contained">Fale Conosco</Button>
                    </div>
                </div>
                <Default>
                    <div className='VideoHome-video'>
                        <iframe
                            width="602"
                            height="339"
                            src="https://www.youtube.com/embed/bNuaEweIxg8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                </Default>
                <Mobile>
                    <div className='VideoHome-video'>
                        <iframe
                            width="390"
                            height="219"
                            src="https://www.youtube.com/embed/bNuaEweIxg8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                </Mobile>

            </div>
        );
    }
}
