import React from 'react';
import Typography from '@material-ui/core/Typography';
import '../../css/Us.css';

export default class Believes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className='Us-message'>
                <ol>
                    <li>
                        <Typography variant='subtitle2' paragraph>
                            <b>Deus</b> – Temos consciência  de que fazemos parte de um todo maior, e esse todo, acreditamos ser Deus. É da nossa fé em Deus que norteamos todas as razões da nossa existência, buscando traduzi-la de maneira concreta em ações que beneficiem todos aqueles à nossa volta.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2' paragraph>
                            <b>Senso de dono</b> – Prezamos por profissionais engajados e comprometidos, que assumam suas atividades como se fossem donos do negócio, dedicando o melhor de seu potencial e inteligência na realização de suas atividades.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2' paragraph>
                            <b>Meritocracia</b> – Desejamos ser justos com nossos colaboradores, valorizando e promovendo aqueles que se dedicam a ir além das expectativas, fazendo sempre mais do que é solicitado.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2' paragraph>
                            <b>Desenvolvimento de Talentos e Autodesenvolvimento</b> – Acreditamos que um bom profissional está sempre em busca de aprimoramento, portanto, estaremos sempre oferecendo oportunidades e investindo em nossos colaboradores a fim de formar profissionais de alto desempenho.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle2' paragraph>
                            <b>Parceria</b> – Acreditamos que o sucesso e o crescimento de uma empresa são construídos através de alianças, que perpetuam baseadas no espírito de equipe e na soma de esforços.
                        </Typography>
                    </li>
                </ol>
            </div>
        );
    }
}
