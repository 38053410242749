import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../../css/MediaCard.css';

export default function MediaCard(props) {
  const { children, src, title, message, display, ...other } = props;
  const messageArray = message.split("-");

  return (
    <div className='MediaCard'>
      <Card sx={{ maxWidth: "100%" }}>
        <CardMedia
          component="img"
          height="100%"
          image={src}
          alt="green iguana"
          className='MediaCard-img'
        />
        {display ?
          <CardContent className='card-content'>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <div>
              {messageArray.map((msg) => (
                <Typography variant='body2' color='text.secondary' paragraph>
                  {msg}
                </Typography>
              ))}
            </div>
          </CardContent> :
          null}
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </div>
  );
}