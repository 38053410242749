import React from 'react';
import './css/App.css';
import axios from 'axios';
import VideoHome from './js/components/VideoHome';
import InfoGrid from './js/components/InfoGrid';
import GridCompanies from './js/components/GridCompanies';
import Caroussel from './js/components/Caroussel';
import { useMediaQuery } from 'react-responsive';
import Typography from '@material-ui/core/Typography';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 850 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 851 })
  return isNotMobile ? children : null
}

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: []
    }
  }

  componentDidMount() {
    // axios.get('http://localhost:3000/users').then(
    //   res => {
    //     const users = res.data;
    //     this.setState({ users });
    //   }
    // )
  }

  render() {
    return (
      <div className="App">
        <Default>
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              height: 0,
              zIndex: -1,
              marginTop: "-1rem"
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: "-0.3%",
                width: "100%",
                height: "100%"
              }}
              src="https://www.youtube.com/embed/bNuaEweIxg8?autoplay=1&loop=1&mute=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=bNuaEweIxg8"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </Default>
        <Mobile>
          <div className='VideoHome-video-up'>
            <iframe
              width="100%"
              height="70%"
              src="https://www.youtube.com/embed/bNuaEweIxg8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          </div>
        </Mobile>

        <div>
          <InfoGrid />
        </div>
        <div style={{ margin: '3rem auto' }}>
          <Typography variant='h3' style={{ marginBottom: '3rem' }}>
            <b><span className='we'>EMPRESAS DO GRUPO</span></b>
          </Typography>
          <div className='App-companies'>
            <GridCompanies />
          </div>
        </div>
        <div>
          <Caroussel />
        </div>
        <VideoHome />
      </div>
    );
  }
}
