import React from 'react';
import Typography from '@material-ui/core/Typography';
import UsTabs from '../components/UsTabs';
import '../../css/Us.css';

export default class Us extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className="Us">
                <div className="Us-title">
                    <img src='https://imagesu2.blob.core.windows.net/img/b1.jpg'></img>
                    <div className='Us-message'>
                        <Typography variant='subtitle2' paragraph>
                            União, não é apenas o que se transmite, mas também o que se acredita!
                            Localizado no Leste de Minas na cidade de Iapu nasceu o GRUPO U2, da confiança de que nessas terras seria possível produzir alimentos e bons negócios para o mundo.
                        </Typography>
                        <Typography variant='subtitle2' paragraph>
                            O grupo União aproveita as oportunidades de um mercado em crescente expansão, para estar entre as maiorias agroindústria do estado de Minas Gerais, com sede em Iapu e Uberlândia e com pensamento visionário, fundamental para o desenvolvimento regional, emplacando tecnologia de ponta e processos eficientes, com o objetivo de oferecer soluções completas, o grupo U2 dispõe de um leque de produtos e serviços sempre em conexão com a cadeia produtiva.
                        </Typography>
                        <Typography variant='subtitle2' paragraph>
                            Seja no fornecimento de produtos agrícolas para a sua lavoura, soluções em transporte, trading e também na nutrição do seu rebanho, no fornecimento de farelo de soja, óleo degomado ou soja integral. Agindo assim, o Grupo U2 fortalece ainda mais a relação com seus parceiros, olhando sempre para o futuro e acompanhando o crescimento do agronegócio brasileiro.
                        </Typography>
                        <Typography variant='subtitle2' paragraph>
                            O setor de nutrição animal conta com diversas linhas de rações para bovinos, suínos, equinos, aves e pets, atendendo diferentes regiões do país e o setor logístico da empresa cuida para que todos os prazos sejam cumpridos rigorosamente, por tudo isso o Grupo U2 é referência na produção e entrega.
                            Através de um crescimento sustentável dedicamos a reforçar a relação com nossos parceiros.

                        </Typography>
                        <Typography variant='subtitle2' paragraph>
                            Grupo U2 - Conexão que gera resultados.
                        </Typography>
                    </div>
                    <div className='Us-video'>
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/bNuaEweIxg8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>

                        </iframe>
                    </div>
                    <div className='Us-companies'>
                        <UsTabs />
                    </div>
                </div>
            </div>
        );
    }
}
