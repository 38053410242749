import React from 'react';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactForm from './ContactForm';
import '../../css/Us.css';
import '../../css/Contact.css';

export default class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className="Us">
                <div className="Us-title">
                    <img src='https://imagesu2.blob.core.windows.net/img/b5.jpg'></img>
                </div>
                <div className='Contact'>
                    <div className='Contact-section-1'>
                        <img style={{ width: '100%', marginBottom: '2rem' }} src='https://imagesu2.blob.core.windows.net/img/u2-contact.png'></img>
                        <div>
                            <Typography paragraph variant='subtitle2'>
                                Para maiores informações acione nossa Central de Relacionamento através do formulário ao lado. <b>Se preferir, entre em contato conosco através dos telefones abaixo, cujo horário de atendimento é de segunda a sexta das 07h30 às 18h00.</b>
                            </Typography>
                        </div>
                        <div>
                            <div className="Footer-icons-text">
                                <PhoneIcon fontSize="large" />
                                <Typography variant="h6">
                                    0800 355 5555
                                </Typography>
                            </div>
                            <div className="Footer-icons-text">
                                <EmailIcon fontSize="large" />
                                <Typography variant="h6">
                                    contato@gpuniao.com.br
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className='Contact-section-2'>
                        <ContactForm />
                    </div>
                </div>
            </div>
        );
    }
}
